import React from 'react'
import { Link, graphql } from 'gatsby'
import FitText from '@kennethormandy/react-fittext'

// Ours
import SEO from '../components/SEO'
import { BlockWrapper } from '../components/MainBlocks'
import { H4 } from '../components/Headings'

const FourOhFourPage = props => {
  const data = props.data
  let title = '404 – Page not found'

  return (
    <React.Fragment>
      <SEO title={title} />
      <BlockWrapper maxWidth={5} bg="magenta" color="white">
        <div className="min-height-100vh">
          <div className="mb3 md-mt3 md-mb4 max-width-4 font-weight-200 line-height-2">
            <FitText compressor={1.5} minFontSize={24} maxFontSize={120}>
              <React.Fragment>
                <h1
                  className="inline"
                  style={{
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                    lineHeight: 'inherit',
                  }}>
                  Oops! Seems like you’re lost.
                </h1>
                <br />
                Let us guide you to one of our recently complete
                <span className="widont">&nbsp;</span>projects.
              </React.Fragment>
            </FitText>
          </div>
          <H4 is="h2" mb={0}>
            Recent work
          </H4>
          <ul className="list-style-none m0 p0">
            {data.projects.edges.map(({ node }, index) => {
              return (
                <li key={`RecentWork_${node.id}_${index}`}>
                  <Link to={`/our-work/${node.slug}`} className="white">
                    {node.title}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </BlockWrapper>
    </React.Fragment>
  )
}

export default FourOhFourPage

export const query = graphql`
  query FourOhFourQuery {
    wp {
      generalSettings {
        title
      }
    }

    projects: allWpProject(
      limit: 5
      filter: { projectDetails: { projectStatus: { eq: "completed" } } }
    ) {
      edges {
        node {
          id
          title
          slug
          uri
          projectDetails {
            projectStatus
          }
        }
      }
    }
  }
`
